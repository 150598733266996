import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancelDialogService {

  archivosActivo: Subject<boolean> = new Subject<boolean>();
  cargarSaldo: Subject<boolean> = new Subject<boolean>();
  pedidoActivo: Subject<boolean> = new Subject<boolean>();
  configEmpresa: Subject<boolean> = new Subject<boolean>();
  crearSucursal: Subject<boolean> = new Subject<boolean>();
  crearTeamAc: Subject<boolean> = new Subject<boolean>();
  crearMiembro: Subject<boolean> = new Subject<boolean>();
  preCompra: Subject<boolean> = new Subject<boolean>();

  reiniciarBanderas: Subject<boolean> = new Subject<boolean>();
  esperarCarga: Subject<boolean>= new Subject<boolean>();
  pedidoCreado: Subject<any> = new Subject();
  pedidoApretoNuevoPeroYaEstabaCreadoYEstabaEnElTimeout: Subject<any> = new Subject();
  miembroCreado: Subject<any> = new Subject();
  sucursalCreada: Subject<any> = new Subject();
  teamCreado: Subject<any> = new Subject();
  
  cajaPagado: Subject<any> = new Subject();
  cajaPendiente: Subject<any> = new Subject();

  actualizarFotoPerfil: Subject<any> = new Subject();

  seleccionarFecha: Subject<boolean> = new Subject<boolean>();
  
  constructor() { }
}
